.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.container {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 200px 1fr 200px;
  grid-template-areas:
      "header"
      "main"
      "footer";

  @media (max-width: 768px) {
    grid-template-rows: 150px 1fr 150px;
  }

  > header {
    grid-area: header;
    background-color: red;
  }
  > main {
    grid-area: main;
    background-color: green;
  }
  > footer {
    grid-area: footer;
    background-color: blue;
  }
}
