.wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    ul {
        li {
            word-wrap: anywhere;
        }
    }
}
.map {
    margin: 0 auto;
    padding-top: 30px;
    > div {
        height: 400px;
        width: 400px;
    }
}

